import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.css"
import tooth from "../images/tooth-icon-white@2x.png"
import { graphql } from "gatsby"
import Img from "gatsby-image"

const MeetDrClauss = ({ data }) => (
  <Layout>
    <SEO title="Meet Dr. Clauss" />
    <div className="container">
      <div className="banner">
        <h1>Meet Dr. Clauss</h1>
      </div>
      <div className="featured-image">
        <Img fluid={data.meetDrClauss.childImageSharp.fluid} alt="meet-dr-clauss"/>
      </div>
      <div className="featured-content">
        <h2 className="headline">About Dr. Ben</h2>
        <p className="rich-text">
          Dr. Ben Clauss was born in Orchard Park, NY and raised in West Falls,
          NY. He attended East Aurora High School and graduated Magna Cum Laude
          from St. Lawrence University. He graduated from the University at
          Buffalo School of Dental Medicine and then completed a one year
          residency in general dentistry at Buffalo General Hospital. He is a
          member of the American Dental Association, New York State Dental
          Association, and 8th District Dental Association. In his spare time he
          enjoys woodworking, cycling, skiing, and spending time with his wife
          and two children.
        </p>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    meetDrClauss: file(relativePath: { eq: "meet-dr-clauss.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default MeetDrClauss
